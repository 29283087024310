import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TopIcon from '../../Assets/Icons/TopIcon';
import Bottom from '../../Assets/Icons/Bottom';
import MaxBottom from '../../Assets/Icons/MaxBottom';
import MaxTop from '../../Assets/Icons/MaxTop';
import { useSelector } from 'react-redux';
import { squircle } from 'ldrs'
import Loading from '../../Assets/Loading/Loading';

const Channles = () => {
  squircle.register()
  const [initialBouquets, setInitialBouquets] = useState([]);
  const [initialVod, setInitialVod] = useState([]);
  const [initialSeries, setInitialSeries] = useState([]);
  const [service, setService] = useState([]);
  const [packages , setPackage] = useState([])
  const [serviceId, setServiceId] = useState();
  const [packageId, setPackageId] = useState();
  const [loading , setLoading] = useState(false)

  const [bouquetData, setBouquetData] = useState({
    live: initialBouquets,
    vod: initialVod,
    series: initialSeries,
  });

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSection, setSelectedSection] = useState('live');

  const fetshServices = async () => {
    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_SERVER + '/service/all' , {
        params: {
          limit: 10,
        }
      });
      if (response.data) {
        setService(response.data.services);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  }

  const fetshPackages = async () =>{
    try{
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_SERVER}/packages/packages-by-service/${serviceId}`)
        if(response.data && response.data.packages.length > 0){
            setPackage(response.data.packages)
        }
    }catch (error) {
        console.log('error lors du excution : ', error)
    } finally{
      setLoading(false)
    }
  }

  const fetchChannel = async () => {
    try {
      setLoading(true)
      const response = await axios.get(process.env.REACT_APP_SERVER + '/channel/' + packageId, {
        headers: {
          'Authorization' : `Bearer ${token_admin}`,
        },
      });
      const data = response.data[0];

      setInitialBouquets(data.LiveBouquet || []);
      setInitialVod(data.Vod || []);
      setInitialSeries(data.SerieBouquet || []);

      setBouquetData({
        live: data.LiveBouquet || [],
        vod: data.Vod || [],
        series: data.SerieBouquet || [],
      });
    } catch (error) {
      console.error('Error fetching channels:', error);
    }finally{
      setLoading(false)
    }
  };

  useEffect(()=>{
    fetchChannel()
    fetshServices()
    fetshPackages()
  } , [serviceId , packageId])

  const [sortedBouquets, setSortedBouquets] = useState({});

  const sortMaxTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...selectedBouquets, ...unselectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = 0; i < updatedBouquets.length; i++) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i - selectedIndex > 0) {
          // Move the selected bouquet one position up
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i - 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };    

  const sortMaxBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...unselectedBouquets, ...selectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = updatedBouquets.length; i >= 0; i--) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i + selectedIndex < updatedBouquets.length) {
          // Move the selected bouquet one position down
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i + 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };    

  const selectAll = () => {
    const allIds = [];
    for (const section in bouquetData) {
      const sectionBouquets = bouquetData[section];
      const sectionIds = sectionBouquets.map((bouquet) => bouquet._id);
      allIds.push(...sectionIds);
    }

    setSelectedIds(allIds);
  };

  const unselectAll = () => {
    setSelectedIds([]);
  };

  const toggleSelection = (bouquetId) => {
    if (selectedIds.includes(bouquetId)) {
      setSelectedIds(selectedIds.filter((_id) => _id !== bouquetId));
    } else {
      setSelectedIds([...selectedIds, bouquetId]);
    }
  };

  const getSelectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => selectedIds.includes(bouquet._id));
  };

  const getUnselectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => !selectedIds.includes(bouquet._id));
  };

  const getBouquetsForSection = () => {
    return bouquetData[selectedSection] || [];
  };

  const updateBouquets = (section, bouquets) => {
    setBouquetData({
      ...bouquetData,
      [section]: bouquets,
    });
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };


  const getSelectedBouquetsForSection = (section) => {
    const sectionBouquets = bouquetData[section];
  
    return sectionBouquets.map((bouquet) => ({
      _id: bouquet._id,
      name: bouquet.name,
      selected: selectedIds.includes(bouquet._id),
    }));
  }; 

  const token_admin = useSelector((state) => state.admin.token_admin);
  const [loadingSubmission , setLoadingSubmission] = useState(false)

  //Submit Channel
  const handleSubmission = async() => {
    try {
      setLoadingSubmission(true)
      const bouquetDataToSend = {
        liveOrder: getSelectedBouquetsForSection('live'),
        serieOrder: getSelectedBouquetsForSection('series'),
        vodOrder: getSelectedBouquetsForSection('vod'),
      };
  
      const response = await axios.put( process.env.REACT_APP_SERVER + '/channel/updateOrderChannel', bouquetDataToSend , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token_admin}`,
          'Accept-Language' : localStorage.getItem('language')
        },
      });
      if(response.data){
        setSortedBouquets(bouquetDataToSend);
        fetchChannel();
      }
      
    } catch (error) {
      console.error('Error submitting bouquet data:', error);
      alert('err')
    } finally {
      setLoadingSubmission(false)
    }
  }
  //Enable selected channels
  const enableChannels = async () => {
    try {
      setLoading(true);

      const response = await axios.put(process.env.REACT_APP_SERVER + '/channel/enable',
        { ids: selectedIds },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token_admin}`,
            'Accept-Language' : localStorage.getItem('language')
          },
        }
      );

      console.log(response.data.message);
      fetchChannel();
    } catch (error) {
      console.error('Error enabling channels:', error);
    } finally {
      setLoading(false);
    }
  };
    //Enable selected channels
  const disableChannels = async () => {
    try {
      setLoading(true);

      const response = await axios.put(process.env.REACT_APP_SERVER + '/channel/disable',
        { ids: selectedIds },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token_admin}`,
            'Accept-Language' : localStorage.getItem('language')
          },
        }
      );

      console.log(response.data.message);
      fetchChannel();
    } catch (error) {
      console.error('Error enabling channels:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col mx-2 lg:mx-20'>
      <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
        <h1 className='text-2xl text-start'>Bouquets</h1>
        <p className='text-slate-500'>Bouquets Management Interface View & Enable and Disable Channels.</p>
      </div>
        <div className="p-4 rounded-md my-5 px-5">
          <div className='flex space-x-2'>
            <select className='py-2.5 w-full px-2 rounded-md border-2 border-Mybg bg-white' value={serviceId} onChange={(e)=>{setServiceId(e.target.value)}}>
              <option>Select Service</option>
              {service && service.map(item => (
                <option key={item._id} value={item._id}>{item.name}</option>
              ))}
            </select>
            <select className='py-2.5 w-full px-2 rounded-md border-2 border-Mybg bg-white' value={packageId} onChange={(e)=>{setPackageId(e.target.value)}}>
              <option>Select Package</option>
              {packages && packages.map(item => (
                <option key={item._id} value={item._id}>{item.name}</option>
              ))}
            </select>
          </div>
        <div className="flex justify-center mt-4 md:flex-row flex-col items-center w-full space-x-1">
          <div className='flex md:w-full space-x-1'>
            <button onClick={enableChannels}  className="bg-green-500 my-1 w-full md:w-1/6 text-white px-4 py-2.5 rounded-md hover:bg-green-400">Enable</button>
            <button onClick={disableChannels}  className="bg-red-500 w-full md:w-1/6 my-1 text-white px-4 py-2.5 rounded-md hover:bg-red-400">Disable</button>
            <button className="bg-Mybg my-1 w-1/2 md:w-full text-white px-4 py-2.5 rounded-md hover:bg-MybgHover" onClick={selectAll}>Select All</button>
            <button className="bg-Mybg w-1/2 md:w-full my-1 text-white px-4 py-2.5 rounded-md hover:bg-MybgHover" onClick={unselectAll}>UnSelectAll</button>
          </div>
          <div className='flex space-x-1'>
            <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2.5 rounded-md hover:bg-MybgHover" onClick={sortTop}><TopIcon /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 rounded-md hover:bg-MybgHover" onClick={sortBottom}><Bottom /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxTop}><MaxTop /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxBottom}><MaxBottom /></button>
          </div>
        </div>
        <div className="flex mt-2 flex-col md:flex-row">
          <button className={`${selectedSection === 'live' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('live')}>Live Bouquets</button>
          <button className={`${selectedSection === 'vod' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1 md:mx-2 my-1 md:my-0`} onClick={() => handleSectionChange('vod')}>Vod</button>
          <button className={`${selectedSection === 'series' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('series')}>Series Bouquets</button>
        </div>
        {
          loading 
          ? 
          <>
            <Loading />
          </>
          : 
          <div className="max-h-72 overflow-y-auto bg-white px-4 py-2 border-2 border-slate-300 rounded-md mt-2">
            <ul>
              {getBouquetsForSection().map((bouquet) => (
                <li key={bouquet._id} onClick={() => toggleSelection(bouquet._id)} className={`cursor-pointer py-4 px-4 border-2 border-Mybg my-1 ${selectedIds.includes(bouquet._id) ? 'bg-slate-200' : ''} hover-bg-slate-200 rounded-md`}>
                  {bouquet.name}
                  {
                    bouquet.active ? (
                      <span className='text-green-500 flex justify-start'>(Active)</span>
                    ) : (
                      <span className='text-red-500  flex justify-start'>(Not Active)</span>
                    )
                  }
                </li>
              ))}
            </ul>
          </div>
        }
        <div className="flex justify-center">
          <button className="bg-Mybg hover:bg-MybgHover rounded-md py-2 md:w-1/3 w-full text-white mt-2" onClick={handleSubmission}>
            {
              loadingSubmission ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : "Submit"
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default Channles