import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import FormatDate from '../../helpers/FormatDate';

const Services = () => {
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(parseInt(process.env.REACT_APP_PAGE));
    const [limit, setLimit] = useState(parseInt(process.env.REACT_APP_LIMIT));
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('');
    const searchRef = useRef();

    const token_admin = useSelector((state) => state.admin.token_admin);

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        const value = searchRef.current.value;
        setSearchText(value);
    };

    const fetshService = useCallback(async() => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/all' , {
                params: {
                    page: page,
                    limit: limit,
                    search: searchText.trim()
                }
            });
            if (response.data) {
                setService(response.data.services);
                setTotalRows(response.data.totalDocs);
            }
        } catch (error) {
            console.error('Error fetching services:', error);
        } finally {
            setLoading(false);
        }
    }, [page,limit,searchText]);

    useEffect(() => {
        fetshService();
    }, [fetshService]);

    const enableSerice = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/service/enable/${id}`, {} , {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language': localStorage.getItem('language')
                }
            });
            if(response.data) {
                fetshService();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const disableSerice = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/service/disable/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language': localStorage.getItem('language')
                }
            });
            if(response.data) {
                fetshService();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const columns = [
        {
            name: 'STATUS',
            selector: (row) => row.active,
            cell: (row) => <span className={`${!row.active ? 'bg-red-400' : 'bg-green-500'} text-white text-[10px] rounded-xl py-1 px-4`}>{row.active ? 'ACTIVE' : 'INACTIVE'}</span>,
            sortable: true,
            center: true,
        },
        {
            name: 'NAME',
            selector: (row) => row.name,
            cell: (row) => <Link to={`/admin/packages/${row._id}`} className="text-blue-500 underline">{row.name}</Link>,
            sortable: true,
            center: true,
        },
        {
            name: 'AVAILABLE PACKAGES',
            selector: (row) => row.packageCount,
            sortable: true,
            center: true,
        },
        { 
            name: 'CREDIT', 
            selector: (row) => row.credit, 
            sortable: true, 
            center: true 
        },
        { 
            name: 'CREATED AT', 
            selector: (row) => FormatDate(row.created), 
            sortable: true, 
            center: true 
        },
        {
            name: 'ACTIONS',
            cell: (row) => (
                <div className="flex justify-center items-center">
                    <button disabled={row.active === true} onClick={() => enableSerice(row._id)} className={`text-white rounded-md py-2 px-2 mx-1 my-1 ${row.active === true ? 'bg-green-300' : 'bg-green-500 hover:bg-green-400'}`}>Enable</button>
                    <button disabled={row.active === false} onClick={() => disableSerice(row._id)} className={`text-white rounded-md py-2 px-2 mx-1 my-1 ${row.active === false ? 'bg-red-300' : 'bg-red-500 hover:bg-red-400'}`}>Disable</button>
                </div>
            )
        },
    ];

    return (
        <div className='flex flex-col mx-2 lg:mx-20'>
            <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
                <h1 className='text-2xl text-start'>Services</h1>
                <p className='text-slate-500'>Services Management Interface.</p>
            </div>
            <div className='my-5'>
                {loading ? (
                    <>
                        <Loading />
                        <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                            <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search by Service Name"
                                defaultValue={searchText}
                                ref={searchRef}
                                className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full  shadow-md' 
                            />
                            <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                                <button onClick={handleSubmitSearch} className='bg-Mybg hover:bg-MybgHover text-white py-1 px-2 rounded-md'>
                                    Submit
                                </button>
                            </div>
                        </div>
                        <DataTable 
                            columns={columns} 
                            data={service} 
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={(page) => setPage(page)}
                            onChangeRowsPerPage={(newLimit) => setLimit(newLimit)}
                            className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
                            striped
                            highlightOnHover
                            paginationPerPage={limit}
                            paginationRowsPerPageOptions={[5, 10, 15]}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default Services;