import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Update from '../../../Assets/Icons/Update';
import Details from '../../../Assets/Icons/Details';
import ModalDetailsSubscribtion from './ModalDetailsSubscribtion/ModalDetailsSubscribtion'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png'
import Vide from '../../../Assets/Pics/Vide.png'
import TableData from './TableData'
import FormatDataUnix from '../../../helpers/FormatDataUnix';

const MySubscribtion = () => {

  const scrollFromLeftVariants = {
    hidden: { x: -2000 },
    visible: { x: 10, transition: { type: 'tween', duration: 0.8 } },
  };

  //Modal Details
  const [name , setName] = useState();
  const [detailsOpen , setDetailsOpen] = useState(false)
  const [paymentStatus , setPaymentStatus] = useState()
  const [Duration , setDuration] = useState()
  const [SubDate , setSubDate] = useState()
  const [deviceType , setDeviceType] = useState()
  const [price , setPrice] = useState()
  const [deviceDetails , setDeviceDetails] = useState({})
  const [page, setPage] = useState(parseInt(process.env.REACT_APP_PAGE));
  const [limit, setLimit] = useState(parseInt(process.env.REACT_APP_LIMIT));
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef();

  const DetailsModal = (paymentStatus , name , SubDate , deviceType , price , Duration , deviceDetails) =>{
    ToggleActionButton(null)
    setDetailsOpen(true)
    setName(name)
    setPaymentStatus(paymentStatus)
    setDuration(Duration)
    setSubDate(SubDate)
    setDeviceType(deviceType)
    setPrice(price)
    {
      deviceType === 'activeCode' 
      ?
      setDeviceDetails({
        code : deviceDetails.activeCode.code
      })
      :
      deviceType === 'mac' 
      ?
      setDeviceDetails({
        mac : deviceDetails.mac.macAddress
      })
      :
      setDeviceDetails({
        userName : deviceDetails.m3u.userName,
        password : deviceDetails.m3u.password
      })
    }
  }

  const CloseDetails = () =>{
    setDetailsOpen(false)
  }

  const [openMenuId, setOpenMenuId] = useState(null);

  const ToggleActionButton = (id) => {
    if (openMenuId === id) {
      setOpenMenuId(null);
    } else {
      setOpenMenuId(id);
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((subscription) => {
          if (subscription.id === id) {
            return { ...subscription, open: true };
          } else {
            return { ...subscription, open: false };
          }
        })
      );
    }
  };

  const navigate = useNavigate()

  //Translation
  const {t} = useTranslation('global')

  //fetsh Subscription

  const [loading , setLoading] = useState(false)
  const [userId , setUserId] = useState(Cookies.get('id') || '')
  const token = useSelector((state) => state.auth.token);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetshSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_SERVER + '/subscription/subscription-user',
        {
          headers: {
            'Content-Type': 'application/json',
            'secret_key': process.env.REACT_APP_KEY_BACKEND,
            'Authorization': `Bearer ${token}`,
            'Accept-Language': localStorage.getItem('language'),
          },
          params: {
            userId: userId,
            page: page,
            limit: limit,
            search: searchText.trim(),
          },
        }
      );
  
      if (response.data) {
        setSubscriptions(response.data.subscriptions);
        setTotalRows(response.data.totalDocs);
      }
    } catch (error) {
      console.error('Error', error);
    } finally {
      setLoading(false);
    }
  }, [page, limit, searchText, token, userId]); 
  
  useEffect(()=>{
    fetshSubscription()
  } , [fetshSubscription])

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    const value = searchRef.current.value;
    setSearchText(value);
  };

  const handleNextPage = () => {
    if (page < Math.ceil(totalRows / limit)) setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  return (
    <div className='container px-5 mx-auto md:px-20 my-20 md:my-[116px]'>
      <ModalDetailsSubscribtion isOpen={detailsOpen} onClose={CloseDetails} name={name} paymentStatus={paymentStatus} Duration={Duration} SubDate={SubDate} deviceType={deviceType} price={price} deviceDetails={deviceDetails}/>
      <div className='mt-20'>
        <h1 className='md:text-start text-center text-2xl'>{t("MySubscribtion.title")}</h1>
        <p className='text-slate-500 text-center md:text-start text-md mt-2'>
          {t("MySubscribtion.description")}
        </p>
      </div>
      <div className='flex'>
        <div className='my-2 text-center md:text-start mx-auto md:mx-0'>
          <span className='font-md'>{t("MySubscribtion.SortBy")} :</span>
          <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("MySubscribtion.Recent")}</button>
          <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("MySubscribtion.Ancient")}</button>
        </div>
      </div>
      <div className='block md:hidden lg:hidden'>
      {  loading 
        ? 
          <>
            <Loading />
            <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
            </div>
          </>
        : 
      <div className='mt-5 flex flex-wrap'>
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search by Package Name"
            defaultValue={searchText}
            ref={searchRef}
            className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full  shadow-md' 
          />
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
            <button onClick={handleSubmitSearch} className='bg-Mybg hover:bg-MybgHover text-white py-1 px-2 rounded-md'>
              Submit
            </button>
          </div>
        </div>
            {
              subscriptions && subscriptions.length > 0 ? (
              subscriptions.map((item)=>(
                  <motion.div key={item._id} className='w-full flex-grow flex-shrink-0 my-1 cursor-pointer shadow-lg rounded-md border-slate-300 border-2 py-5 px-5 transition-all duration-500 ' initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
                    <div className='flex items-center'>
                        <div className='w-1/2 flex justify-start flex-col'>
                            <h1 className='text-lg inline-flex items-center capitalize'>
                              <span className='mx-1'>{ item.paymentStatus === 'pending' ? <div className='w-2 h-2 bg-yellow-500 rounded-full p-2'></div> : item.paymentStatus === 'success' ? <div className='w-2 h-2 bg-green-500 rounded-full p-2'></div> : <div className='w-2 h-2 bg-red-500 rounded-full p-2'></div>}</span>
                              {item.paymentStatus}  
                            </h1>
                        </div>
                        <div className='w-1/2 flex justify-end'>
                            <span className='bg-Mybg rounded-md text-white py-2 px-2'>{item.packageId.price}$</span>
                        </div>
                    </div>
                    <div className='flex justify-center items-center my-5 flex-col'>
                        <h1 className='text-xl'>{item.packageId.name}</h1>
                        <p className=' text-slate-500 my-2'>{t("MySubscribtion.table.Duration")}&nbsp;:&nbsp;{item.packageId.duration} Days</p>
                        <p className='text-green-500 '>{t("MySubscribtion.table.SubscriptionDate")}&nbsp;:&nbsp;{FormatDataUnix(item.create_date)}</p>
                        <p className='text-red-500 '>{t("MySubscribtion.table.Exp_Date")}&nbsp;:&nbsp;{FormatDataUnix(item.exp_date)}</p>
                    </div>
                    <div className='flex justify-center items-center border-t-2 border-slate-200 py-2 mt-10 '>
                      {/* Update */}
                      <button className='flex justify-center items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700' onClick={()=>{navigate(`updateSubscribtion/${item._id}`)}}><span className='mr-3 text-yellow-500 hover:text-yellow-400 rounded-md p-1'><Update /></span>{t("MySubscribtion.table.Update")}</button>
                      {/* Details */}
                      <button className='flex justify-center items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700' onClick={()=>{DetailsModal(item.paymentStatus , item.packageId.name , FormatDataUnix(item.createdAt) , item.deviceType , item.packageId.price , item.packageId.duration , item.deviceDetails)}}><span className='mr-3 text-green-500 hover:text-green-400 rounded-md p-1'><Details /></span>{t("MySubscribtion.table.Details")}</button>
                    </div>
                  </motion.div>
                ))
              )
            :
            (
            <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
              <img src={Vide} alt='Vide' className='h-full w-full object-contain' />
            </div>
            )
            }
        </div>
    }
    </div>
    <div className="flex md:hidden lg:hidden justify-center mt-5">
      <button
        onClick={handlePrevPage}
        disabled={page === 1}
        className="px-3 py-1 mx-1 text-white bg-blue-500 rounded disabled:opacity-50"
      >
        {t("MySubscribtion.Pagination.previous")}
      </button>
      <span className="px-3 py-1">{`${page} / ${Math.ceil(totalRows / limit)}`}</span>
      <select value={limit} onChange={(e)=>{setLimit(e.target.value)}}  className="px-3 py-1">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
      </select>
      <button
        onClick={handleNextPage}
        disabled={page === Math.ceil(totalRows / limit)}
        className="px-3 py-1 mx-1 text-white bg-blue-500 rounded disabled:opacity-50"
      >
        {t("MySubscribtion.Pagination.next")}
      </button>
    </div>
    <div className='hidden md:block lg:block'>
      <TableData />
    </div>
    </div>
  );
};

export default MySubscribtion;