import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import Update from '../../Assets/Icons/Update'
import Details from '../../Assets/Icons/Details'
import Banned from '../../Assets/Icons/Banned'
// import Delete from '../../Assets/Icons/Delete'
import AuthorizeUser from '../../Assets/Icons/AuthorizeUser'
import Loading from '../../Assets/Loading/Loading'
import LoadingImg from '../../Assets/Pics/loading_img.png'
import DetailsSubscription from './DetailsSubscription'
import ConfirmDeleteSubscription from './ConfirmDeleteSubscription'
import { useNavigate } from 'react-router-dom'
import FormatDate from '../../helpers/FormatDate'

const Subscription = () => {
    const [subscription , setSubscription] = useState([])
    const [loading , setLoading] = useState(false)
    const token_admin = useSelector((state) => state.admin.token_admin);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [page, setPage] = useState(parseInt(process.env.REACT_APP_PAGE));
    const [limit, setLimit] = useState(parseInt(process.env.REACT_APP_LIMIT));
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('');
    const searchRef = useRef();

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        const value = searchRef.current.value;
        setSearchText(value);
    };

    const fetshSubscription = useCallback(async() => {
        try {
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + '/subscription/getAllSubscriptionsWithUserAndPackage' , {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            }, {
                params: {
                    page: page,
                    limit: limit,
                    search: searchText.trim()
                }
            })

            if(response.data) {
                setSubscription(response.data.subscriptions)
                setTotalRows(response.data.totalDocs);
            }
        } catch (error) {
            console.error('Error fetching subscription : ' , error)
        } finally {
            setLoading(false)
        }
    }, [page,limit,searchText]);

    useEffect(()=>{
        fetshSubscription()
    } , [fetshSubscription])

    const mappedData = subscription.map((subscription) => ({
        _id : subscription._id ,
        user: `${subscription.user.FirstName} ${subscription.user.LastName}`,
        userId: `${subscription.user._id}`,
        package: subscription.packageId.name,
        deviceType: subscription.deviceType,
        paymentMethod: subscription.paymentMethod,
        paymentStatus: subscription.paymentStatus,
        paymentDate: subscription.paymentDate ? new Date(subscription.paymentDate).toLocaleDateString() : '-',
        paymentId: subscription.paymentId ? subscription.paymentId : '-',
        duration : subscription.packageId.duration ,
        price : subscription.packageId.price ,
        deviceDetails : subscription.deviceDetails ,
        activationStatus: subscription.activationStatus ? 'Activated' : 'Not Activated',
        createdAt: FormatDate(subscription.createdAt),
    }));

    const columns = [
        { name: 'USER', selector: (row) => row.user, sortable: true, center: true , width: "150px"},
        { name: 'PACKAGE', selector: (row) => row.package, sortable: true, center: true , width: "150px"},
        { name: 'DEVICE TYPE', selector: (row) => row.deviceType, sortable: true, center: true , width: "150px"},
        { name: 'PAYMENT METHOD', selector: (row) => row.paymentMethod, sortable: true, center: true , width: "150px"},
        { name: 'PAYMENT DATE', selector: (row) => row.paymentDate, sortable: true, center: true , width: "150px"},
        { name: 'PAYMENT ID', selector: (row) => row.paymentId, sortable: true, center: true , width: "150px"},
        {
            name: 'PAYMENT STATUS',
            cell: (row) => (
                <span className='inline-flex items-center'>
                    {row.paymentStatus === 'pending' ? <div className='w-2 h-2 bg-yellow-500 rounded-full p-2 mr-1'></div> :
                        row.paymentStatus === 'success' ? <div className='w-2 h-2 bg-green-500 rounded-full p-2 mr-1'></div> :
                            <div className='w-2 h-2 bg-red-500 rounded-full p-2 mr-1'></div>}{row.paymentStatus}
                </span>
            ),
            center: true,
            width: "150px"
        },
        {
            name: 'ACTIVATION STATUS',
            cell: (row) => (
                <span className='inline-flex items-center'>
                    {row.activationStatus === 'Activated' ? <div className='text-green-500'>{row.activationStatus}</div> :
                        <div className='text-red-500'>{row.activationStatus}</div>}
                </span>
            ),
            center: true,
            width: "150px"
        },
        { name: 'CREATED AT', selector: (row) => row.createdAt, sortable: true, center: true , width: "150px"},
        {
            name: 'ACTIONS',
            cell: (row) => (
                <div className="flex justify-center items-center">
                    <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={() => { navigate(`/admin/subscription/${row._id}/${row.userId}`) }}><span className='text-yellow-500 hover:text-yellow-400 rounded-md p-1'><Update /></span></button>
                    <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={() => { DetailsModal(row.package, row.paymentStatus, row.duration, row.createdAt, row.deviceType, row.price, row.deviceDetails , row.paymentDate , row.paymentId); }}>
                        <span className='text-blue-500 hover:text-blue-400 rounded-md p-1'>
                            <Details />
                        </span>
                    </button>
                    {
                        (row.paymentStatus === 'pending' || row.paymentStatus === 'success') ? (
                            <button onClick={() => { disableSubscription(row._id) }} className='flex justify-center items-center w-full rounded-md text-sm text-gray-700'><span className='text-orange-500 hover:text-orange-400 rounded-md p-1'><Banned /></span></button>
                        ) : (
                            <button onClick={() => { enableSubscription(row._id) }} className='flex justify-center items-center w-full rounded-md text-sm text-gray-700'><span className='text-orange-500 hover:text-orange-400 rounded-md p-1'><AuthorizeUser /></span></button>
                        )
                    }
                </div>
            ) ,
            center : true , 
            width: "150px"
        },
    ];

    //Details Model Sub
    const [name , setName] = useState();
    const [paymentStatus , setPaymentStatus] = useState()
    const [Duration , setDuration] = useState()
    const [SubDate , setSubDate] = useState()
    const [deviceType , setDeviceType] = useState()
    const [price , setPrice] = useState()
    const [paymentDate , setPaymentDate] = useState()
    const [paymentId , setPaymentId] = useState()
    const [deviceDetails , setDeviceDetails] = useState({})

    const DetailsModal = (name, paymentStatus, Duration, SubDate, deviceType, price, deviceDetails , paymentDate , paymentId) => {
        setDetailsOpen(true);
        setName(name);
        setPaymentStatus(paymentStatus);
        setDuration(Duration);
        setSubDate(SubDate);
        setDeviceType(deviceType);
        setPrice(price);
        setPaymentDate(paymentDate)
        setPaymentId(paymentId)
    
        if (deviceType === 'activeCode' && deviceDetails?.activeCode?.code) {
            setDeviceDetails({
                code: deviceDetails.activeCode.code
            });
        } else if (deviceType === 'mac' && deviceDetails?.mac?.macAddress) {
            setDeviceDetails({
                mac: deviceDetails.mac.macAddress
            });
        } else if (deviceType !== 'activeCode' && deviceType !== 'mac' && deviceType === 'm3u' && deviceDetails?.m3u?.userName && deviceDetails?.m3u?.password) {
            setDeviceDetails({
                userName: deviceDetails.m3u.userName,
                password: deviceDetails.m3u.password
            });
        } else {
            setDeviceDetails({});
        }
    };

    const CloseDetails = () => {
        setDetailsOpen(false)
    }

    //Disable subscription By Id
    const disableSubscription = async (id) => {
        try{
            setLoading(true)
            const formData = new FormData();
            formData.append('subscriptionId', id);
            const response = await axios.post(process.env.REACT_APP_SERVER + '/subscription/disabelSubscription', formData , {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Content-Type': 'application/json',
                    'Accept-Language' : localStorage.getItem('language')
                }
            }) 
            if(response.data) {
                fetshSubscription()
            }
        } catch (error) {
            console.error('Error : ' ,error)
        } finally {
            setLoading(false)
        }
    }

    //Enable subscription By Id
    const enableSubscription = async (id) => {
        try{
            setLoading(true)
            const formData = new FormData();
            formData.append('subscriptionId', id);
            const response = await axios.post(process.env.REACT_APP_SERVER + '/subscription/enableSubscription', formData , {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Content-Type': 'application/json',
                    'Accept-Language' : localStorage.getItem('language')
                }
            }) 
            if(response.data) {
                fetshSubscription()
            }
        } catch (error) {
            console.error('Error : ' ,error)
        } finally {
            setLoading(false)
        }
    }
    //Model Delete Subscription
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [subscriptionName , setSubscriptionName] = useState();
    const [subscriptionId , setSubscriptionId] = useState()

    const openConfirmation = ( subscriptionName , subscriptionId ) => {
        setSubscriptionName(subscriptionName)
        setSubscriptionId(subscriptionId)
        setConfirmationOpen(true);
    };
    
    const closeConfirmation = () => {
        setConfirmationOpen(false);
    };
    
    const handleConfirm = () => {
        closeConfirmation();
    };

    const navigate = useNavigate()

  return (
    <div className='flex flex-col mx-2 lg:mx-20'>
        <ConfirmDeleteSubscription isOpen={isConfirmationOpen} onClose={closeConfirmation}  onConfirm={handleConfirm} subscriptionName={subscriptionName} id={subscriptionId} fetshSubscription={fetshSubscription} />
        <DetailsSubscription isOpen={detailsOpen} onClose={CloseDetails} name={name} paymentStatus={paymentStatus} Duration={Duration} SubDate={SubDate} deviceType={deviceType} price={price} deviceDetails={deviceDetails} paymentDate={paymentDate} paymentId={paymentId}/>
        <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
            <h1 className='text-2xl text-start'>Subscription</h1>
            <p className='text-slate-500'>Subscription Management Interface.</p>
        </div>
        <div className='my-5'>
        {
            loading ? (
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            ) : (
                <>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search By First Name , Last Name Or Package Pame"
                            defaultValue={searchText}
                            ref={searchRef}
                            className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full  shadow-md' 
                        />
                        <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                            <button onClick={handleSubmitSearch} className='bg-Mybg hover:bg-MybgHover text-white py-1 px-2 rounded-md'>
                                Submit
                            </button>
                        </div>
                    </div>
                    <DataTable 
                        columns={columns} 
                        data={mappedData} 
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={(page) => setPage(page)}
                        onChangeRowsPerPage={(newLimit) => setLimit(newLimit)}
                        className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
                        striped
                        highlightOnHover
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[5, 10, 15]}
                    />
                </>
            )
        }
        </div>
    </div>
  )
}

export default Subscription