import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Top from '../../../../Assets/Icons/TopIcon';
import Bottom from '../../../../Assets/Icons/Bottom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Loading from '../../../../Assets/Loading/Loading';
import MaxTop from '../../../../Assets/Icons/MaxTop';
import MaxBottom from '../../../../Assets/Icons/MaxBottom';
import Cookies from 'js-cookie';
import { squircle } from 'ldrs'
import Success from '../../../../Assets/Alert/Success';
import Error from '../../../../Assets/Alert/Error';
import ActiveCodeIcon from '../../../../Assets/Icons/ActiveCodeIcon';

const UpdateSubscribtion = () => {
  squircle.register()
  const {subscriptionId} = useParams()

  //Live Bouquet
  const [initialBouquets, setInitialBouquets] = useState([]);
  const [initialVod, setInitialVod] = useState([]);
  const [initialSeries, setInitialSeries] = useState([]);
  const [loading , setLoading] = useState(false)

  const [bouquetData, setBouquetData] = useState({
    live: initialBouquets,
    vod: initialVod,
    series: initialSeries,
  });

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSection, setSelectedSection] = useState('live');

  const [sortedBouquets, setSortedBouquets] = useState({});

  const sortMaxTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...selectedBouquets, ...unselectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = 0; i < updatedBouquets.length; i++) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i - selectedIndex > 0) {
          // Move the selected bouquet one position up
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i - 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };   

  const sortMaxBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...unselectedBouquets, ...selectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = updatedBouquets.length - 1; i >= 0; i--) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i + selectedIndex < updatedBouquets.length) {
          // Move the selected bouquet one position down
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i + 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };    

  const selectAll = () => {
    const allIds = [];
    for (const section in bouquetData) {
      const sectionBouquets = bouquetData[section];
      const sectionIds = sectionBouquets.map((bouquet) => bouquet._id);
      allIds.push(...sectionIds);
    }

    setSelectedIds(allIds);
  };

  const unselectAll = () => {
    setSelectedIds([]);
  };

  const toggleSelection = (bouquetId) => {
    const bouquetIndex = bouquetData[selectedSection].findIndex(bouquet => bouquet._id === bouquetId);
    
    if (bouquetIndex !== -1) {
      const updatedBouquets = [...bouquetData[selectedSection]];
      const updatedBouquet = { ...updatedBouquets[bouquetIndex] };
      
      // Toggle the 'selected' property
      updatedBouquet.selected = !updatedBouquet.selected;
      
      updatedBouquets[bouquetIndex] = updatedBouquet;
      setBouquetData({
        ...bouquetData,
        [selectedSection]: updatedBouquets,
      });
      
      const selectedIdsSet = new Set(selectedIds);
  
      if (updatedBouquet.selected) {
        // If the bouquet is selected, add it to the set
        selectedIdsSet.add(bouquetId);
      } else {
        // If the bouquet is unselected, remove it from the set
        selectedIdsSet.delete(bouquetId);
      }
  
      setSelectedIds(Array.from(selectedIdsSet));
    }
  };  

  const getSelectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => selectedIds.includes(bouquet._id));
  };

  const getUnselectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => !selectedIds.includes(bouquet._id));
  };

  const getBouquetsForSection = () => {
    return bouquetData[selectedSection] || [];
  };

  const updateBouquets = (section, bouquets) => {
    setBouquetData({
      ...bouquetData,
      [section]: bouquets,
    });
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };  

  //Device
  const token = useSelector((state) => state.auth.token);
  const [subscriptionName , setSubscriptionName] = useState()
  const [deviceType , setDeviceType] = useState()
  const [deviceDetails , setDeviceDetails] = useState({})
  const [packageId , setPackageId] = useState()

  const fetshSubuscriptionBySubId = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_SERVER + `/subscription/${subscriptionId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'secret_key': process.env.REACT_APP_KEY_BACKEND,
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        const data = response.data.subscription;
        setInitialBouquets(data.liveBouquet || []);
        setInitialVod(data.vodBouquet || []);
        setInitialSeries(data.seriesBouquet || []);

        setBouquetData({
          live: data.liveBouquet || [],
          vod: data.vodBouquet || [],
          series: data.seriesBouquet || [],
        });

        setPackageId(data.packageId._id)
        setSubscriptionName(data.packageId.name);
        setDeviceType(data.deviceType);
  
        let details;
        if (Array.isArray(data.deviceDetails) && data.deviceDetails.length > 0) {
          details = data.deviceDetails.find((detail) => detail.type === deviceType);
        } else {
          details = data.deviceDetails;
        }
  
        if (details) {
          setDeviceDetails(details);
        }
      }
    } catch (error) {
      console.error('Err', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(()=>{
    fetshSubuscriptionBySubId()
  } , [])

  
  //Translation
  const {t} = useTranslation('global')

  //Update Subscription
  const [loadingSubmission , setLoadingSubmission] = useState(false)
  const [successMessage , setSuccessMessage] = useState()
  const [errMessage , setErrMessage] = useState()
  const getSelectedBouquetsForSection = (section) => {
    const sectionBouquets = bouquetData[section];
  
    return sectionBouquets.map((bouquet) => ({
      _id: bouquet._id,
      name: bouquet.name,
      bouquet_id: bouquet.bouquet_id ,
      selected: selectedIds.includes(bouquet._id) || bouquet.selected,
    }));
  };  

  //Mac Cas
  const isValidMacAddress = (mac) => {
    const macRegex = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/;
    return macRegex.test(mac);
  };

  const formatMacAddress = (mac) => {
    const sanitizedMac = mac.replace(/[^0-9A-Fa-f]/g, '');
    const formattedMac = sanitizedMac.match(/.{1,2}/g).join(':');
    return formattedMac;
  };

  const [newMacAdress , setNewMacAdress] = useState()
  const getNewDeviceDetails = (e) => {
    const inputMacAddress = e.target.value;
    const formattedMacAddress = formatMacAddress(inputMacAddress);
    setNewMacAdress(formattedMacAddress);
  };

  //Active Code cas
  const [randomNumbers, setRandomNumbers] = useState([]);
  const generateRandomNumbers = () => {
    const constantPrefix = '0000';
    const newNumbers = [];
    
    for (let i = 0; i < 8; i++) {
        newNumbers.push(Math.floor(Math.random() * 10));
    }
    const randomCode = constantPrefix + newNumbers.join('');

    if (randomCode.length !== 12 || !/^\d+$/.test(randomCode)) {
        generateRandomNumbers();
    } else {
        setRandomNumbers(randomCode);
    }
  };

  useEffect(() => {
    generateRandomNumbers();
    const intervalId = setInterval(() => {
      generateRandomNumbers();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  //m3u Cas
  const characters = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';

  const generateRandomString = (length) => {
    return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
  };
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const generateCredentials = () => {
    const generatedUserName = generateRandomString(8);
    const generatedPassword = generateRandomString(8);

    setUserName(generatedUserName);
    setPassword(generatedPassword);
  };

  useEffect(() => {
    generateCredentials();
    const intervalId = setInterval(() => {
      generateCredentials();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const updateSubscription = async () => {
    try {
      setLoadingSubmission(true)
  
      let deviceDetails;
  
      if (deviceType === 'activeCode') {
        deviceDetails = {
          activeCode: {
            code: randomNumbers
          }
        };
      } else if (deviceType === 'mac') {
        deviceDetails = {
          mac: {
            macAddress: newMacAdress
          }
        };
      } else {
        deviceDetails = {
          m3u: {
            userName: userName,
            password: password
          }
        };
      }
  
      const dataToSend = {
        userId: Cookies.get('id'),
        packageId: packageId,
        subscriptionId: subscriptionId,
        deviceDetails: deviceDetails,
        liveBouquet: getSelectedBouquetsForSection('live'),
        seriesBouquet: getSelectedBouquetsForSection('series'),
        vodBouquet: getSelectedBouquetsForSection('vod'),
      };
      
      const response = await axios.put(process.env.REACT_APP_SERVER + '/subscription/updateSubscription' , dataToSend , {
        headers: {
          'Content-Type': 'application/json',
          'secret_key': process.env.REACT_APP_KEY_BACKEND,
          'Authorization': `Bearer ${token}`,
          'Accept-Language' : localStorage.getItem('language')
        },
      })

      if(response.data){
        console.log(response.data)
        setSuccessMessage(response.data.message)
      }
    } catch (error) {
      setErrMessage(error.response.data.error)
    } finally {
      setLoadingSubmission(false)
    }
  };  


  return (
    <div className='flex justify-center items-center my-20 md:h-screen  flex-col mx-5'>
      <h1 className='text-xl my-10 text-center'>{t("UpdateSubscribtion.title")} <span className='text-Mybg'>{subscriptionName}</span></h1>
        {/* Success message */}
          {
            successMessage && (
              <div className='flex md:w-1/2 w-full'>
                <Success message={successMessage} />
              </div>
            )
          }
        {/* Err message */}
          {
            errMessage && (
              <div className='flex md:w-1/2 w-full'>
                <Error  className='my-2' message={errMessage} />
              </div>
            )
          }
      <div className='flex flex-col my-2 md:w-1/2 w-full'>
          {deviceType === 'activeCode' && (
            <div className='flex'>
              <div  className='bg-MybgHover py-7 w-full flex justify-center rounded-md'><span className='text-white'>{randomNumbers}</span></div>
              <button onClick={generateRandomNumbers} className="ml-2 bg-Mybg hover:bg-MybgHover text-white py-2 px-4 rounded-md"><ActiveCodeIcon /></button>
            </div>
          )}
          {deviceType === 'mac' && (
            <div>
              <label>Mac :</label>
            <input
              value={newMacAdress ? newMacAdress : ''}
              onChange={getNewDeviceDetails}
              className='outline-none focus:border-Mybg border-Mybg border-2 text-black py-2 rounded-md px-6 text-center my-2 w-full'
            />
            </div>
          )}
          {deviceType === 'm3u' && (
            <div className='mt-2 flex'>
              <div className='flex flex-col w-full'>
                {/* <label>UserName :</label> */}
                <input type='text' className='outline-none my-1 focus:border-Mybg rounded-md py-2 px-4 border-2 border-Mybg' value={`userName : ${userName}`} readOnly />
                <input type='text' className='outline-none my-1 focus:border-Mybg rounded-md py-2 px-4 border-2 border-Mybg' value={`password : ${password}`} readOnly />
              </div>
              <button className="py-2 px-6 items-center rounded-md text-white hover:bg-MybgHover bg-Mybg mx-1 flex justify-center" onClick={generateCredentials}><ActiveCodeIcon/></button>
            </div>
          )}
      </div>
      {/* LiveBouquet */}
      <div className="flex justify-center mt-4 md:flex-row flex-col items-center w-full md:w-1/2">
        <div className='flex md:w-1/2 w-full'>
          <button className="bg-Mybg my-1 w-1/2 md:w-full text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={selectAll}>{t("Checkout.LiveBouquets.SelectAll")}</button>
          <button className="bg-Mybg w-1/2 md:w-full my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={unselectAll}>{t("Checkout.LiveBouquets.UnSelectAll")}</button>
        </div>
        <div className='flex w-full md:w-1/2'>
            <button className="bg-Mybg w-1/4 my-1 text-white px-6 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortTop}><Top /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-6 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortBottom}><Bottom /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-6 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxTop}><MaxTop /></button>
            <button className="bg-Mybg w-1/4 my-1 text-white px-6 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxBottom}><MaxBottom /></button>
        </div>
      </div>
      <div className="flex mt-2 flex-col md:flex-row w-full md:w-1/2">
        <button className={`${selectedSection === 'live' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('live')}>Live Bouquets</button>
        <button className={`${selectedSection === 'vod' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1 md:mx-2 my-1 md:my-0`} onClick={() => handleSectionChange('vod')}>Vod</button>
        <button className={`${selectedSection === 'series' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('series')}>Series Bouquets</button>
      </div>
      {
        loading 
        ? 
        <>
          <Loading />
        </>
        : 
        <div className="max-h-72 overflow-y-auto bg-white px-4 py-2 border-2 border-slate-300 rounded-md mt-2 w-full md:w-1/2">
          <ul>
            {getBouquetsForSection().map((bouquet) => (
              <li key={bouquet._id} onClick={() => toggleSelection(bouquet._id)} className={`cursor-pointer py-4 px-4 border-2 border-Mybg my-1 ${selectedIds.includes(bouquet._id) || bouquet.selected ? 'bg-slate-200' : ''} hover-bg-slate-200 rounded-md`}>{bouquet.name}</li>
            ))}
          </ul>
        </div>
      }
      <div className="flex justify-center md:w-1/2 w-full">
        <button className="bg-Mybg hover:bg-MybgHover rounded-md py-2 w-full text-white mt-2 mx-1" onClick={updateSubscription}>
          {
            loadingSubmission 
            ?
            <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle>  
            :
            t("Checkout.LiveBouquets.button")
          }
        </button>
      </div>
    </div>
  )
}

export default UpdateSubscribtion