import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png';
import { useNavigate } from 'react-router-dom';
import ModalDetailsSubscribtion from './ModalDetailsSubscribtion/ModalDetailsSubscribtion'
import Details from '../../../Assets/Icons/Details';
import { useTranslation } from 'react-i18next';
import Update from '../../../Assets/Icons/Update';
import Extend from '../../../Assets/Icons/Extend';
import Money from '../../../Assets/Icons/Money';
import ModalExtendSubscription from './ModalExtendSubscruption/ModalExtendSubscription';
import ModalPaySubscription from './ModalPaySubscription/ModalPaySubscription';
import FormatDataUnix from '../../../helpers/FormatDataUnix'

const TableData = () => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(Cookies.get('id') || '');
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [page, setPage] = useState(parseInt(process.env.REACT_APP_PAGE));
  const [limit, setLimit] = useState(parseInt(process.env.REACT_APP_LIMIT));
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef();
  //Modal Details
  const [name , setName] = useState();
  const [detailsOpen , setDetailsOpen] = useState(false)
  const [paymentStatus , setPaymentStatus] = useState()
  const [Duration , setDuration] = useState()
  const [SubDate , setSubDate] = useState()
  const [deviceType , setDeviceType] = useState()
  const [price , setPrice] = useState()
  const [deviceDetails , setDeviceDetails] = useState({})
  const DetailsModal = (paymentStatus , name , SubDate , deviceType , price , Duration , deviceDetails) =>{
    ToggleActionButton(null)
    setDetailsOpen(true)
    setName(name)
    setPaymentStatus(paymentStatus)
    setDuration(Duration)
    setSubDate(SubDate)
    setDeviceType(deviceType)
    setPrice(price)
    {
      deviceType === 'activeCode' 
      ?
      setDeviceDetails({
        code : deviceDetails.activeCode.code
      })
      :
      deviceType === 'mac' 
      ?
      setDeviceDetails({
        mac : deviceDetails.mac.macAddress
      })
      :
      setDeviceDetails({
        userName : deviceDetails.m3u.userName,
        password : deviceDetails.m3u.password
      })
    }
  }
  const CloseDetails = () =>{
    setDetailsOpen(false)
  }
  const [openMenuId, setOpenMenuId] = useState(null);
  const ToggleActionButton = (id) => {
    if (openMenuId === id) {
      setOpenMenuId(null);
    } else {
      setOpenMenuId(id);
      setData((prevSubscriptions) =>
      prevSubscriptions.map((subscription) => {
        if (subscription.id === id) {
          return { ...subscription, open: true };
        } else {
          return { ...subscription, open: false };
        }
      })
      );
    }
  };
  //Translation part
  const {t} = useTranslation('global')
  const fetchSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_SERVER + '/subscription/subscription-user',{},
        {
          headers: {
            'Content-Type': 'application/json',
            'secret_key': process.env.REACT_APP_KEY_BACKEND,
            'Authorization': `Bearer ${token}`,
            'Accept-Language': localStorage.getItem('language'),
          },
          params: {
            userId: userId,
            page: page,
            limit: limit,
            search: searchText.trim(),
          },
        }
      );
  
      if (response.data) {
        setData(response.data.subscriptions);
        setTotalRows(response.data.totalDocs);
      }
    } catch (error) {
      console.error('Error', error);
    } finally {
      setLoading(false);
    }
  }, [page, limit, searchText, token, userId]);  

  useEffect(() => {
    fetchSubscription();
    console.log("Server:", process.env.REACT_APP_SERVER);
    console.log("Key Backend:", process.env.REACT_APP_KEY_BACKEND);
  }, [fetchSubscription]);

  const columns = [
    { name: t("MySubscribtion.table.Name") , selector: (row) => row.packageId.name , label: 'Name', sortable: true , center: true},
    { name: t("MySubscribtion.table.DeviceType") , selector: (row) => row.deviceType, label: 'Package Name', sortable: true , center: true , grow: 2},
    {
      name: t("MySubscribtion.table.DeviceDetails"),
      cell: (row) => <DeviceDetailsCell row={row} />,
      width : "200px" ,
      grow: 2,
      sortable: true ,
      center: true
    },
    { name: t("MySubscribtion.table.Price") , selector: (row) => row.packageId.price, label: 'Price', sortable: true , center: true },
    { name: t("MySubscribtion.table.Duration") , selector: (row) => row.packageId.duration ? row.packageId.duration + ' Days' : 'N/A', label: 'Duration', sortable: true , center: true , width : "200px" ,},
    { name: t("MySubscribtion.table.ActivationStatus") , 
      cell: (row) => <span className='inline-flex' >{ row.paymentStatus === 'pending' ? <div className='w-2 h-2 bg-yellow-500 rounded-full p-2 mr-1'></div> : row.paymentStatus === 'success' ? <div className='w-2 h-2 bg-green-500 rounded-full p-2 mr-1'></div> : <div className='w-2 h-2 bg-red-500 rounded-full p-2 mr-1'></div>}{row.paymentStatus}</span> , 
      center: true ,
      grow: 2 ,
      width : "150px" ,
    },
    { name: t("MySubscribtion.table.SubscriptionDate") , selector: (row) => row.create_date ? FormatDataUnix(row.create_date) : 'N/A', label: 'Created At', sortable: true , center: true , width : "170px"},
    { name: t("MySubscribtion.table.Exp_Date") , selector: (row) => row.exp_date ? FormatDataUnix(row.exp_date) : 'N/A', label: 'exp_date', sortable: true , center: true , width : "170px"},
    {
      name: t("MySubscribtion.table.Actions") ,
      cell: (row) => (
        <>
          <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={()=>{navigate(`updateSubscribtion/${row._id}`)}}><span className='text-yellow-500 hover:text-yellow-400 rounded-md p-1'><Update /></span></button>
          <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={()=>{DetailsModal(row.paymentStatus , row.packageId.name , row.createdAt.split("T")[0] , row.deviceType , row.packageId.price , row.packageId.duration , row.deviceDetails)}}><span className='text-blue-500 hover:text-blue-400 rounded-md p-1'><Details /></span></button>
          {
            row.paymentStatus === "success" ? (
              <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={()=>{openExtendOpen(row._id)}}>
                <span className='text-green-500 hover:text-green-400 rounded-md p-1'><Extend /></span>
              </button>
            ) : (
              <button className='flex justify-center items-center w-full rounded-md text-sm text-gray-700' onClick={()=>{openPaySub(row._id)}}>
                <span className='rounded-md p-1'><Money /></span>
              </button>
            )
          }
        </>
      ),
      center: true
    },
  ];
  
  // Open Model Extend
  const [extendOpen , setExtendOpen] = useState(false)
  const [subscriptionIdForExtend , setSubscriptionIdForExtend] = useState()
  const openExtendOpen = ( id ) => {
    setSubscriptionIdForExtend(id)
    setExtendOpen(true)
  }
  const closeExtend = () => {
    setExtendOpen(false)
    fetchSubscription()
  }
  // Open Model Pay Subscription
  const [payOpen , setOpenPay] = useState(false)
  const [subIdForPay , setSubIdForPay] = useState()
  const openPaySub = ( id ) => {
    setSubIdForPay(id)
    setOpenPay(true)
  }
  const closePaySub = () => {
    setOpenPay(false)
    fetchSubscription()
  }

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    const value = searchRef.current.value;
    setSearchText(value);
  };

  // Handle Reseller status
  const reseller = useSelector((state) => state.auth.reseller);
  return (
    <div className="flex justify-center items-center flex-col h-full">
      <ModalDetailsSubscribtion isOpen={detailsOpen} onClose={CloseDetails} name={name} paymentStatus={paymentStatus} Duration={Duration} SubDate={SubDate} deviceType={deviceType} price={price} deviceDetails={deviceDetails}/>
      <ModalExtendSubscription isOpen={extendOpen} onClose={closeExtend} id={subscriptionIdForExtend} />
      <ModalPaySubscription isOpen={payOpen} onClose={closePaySub} id={subIdForPay} />
      {
      loading ? (
        <>
          <Loading />
          <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
            <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
          </div>
        </>
      ) : (
        <>
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search by Package Name"
            defaultValue={searchText}
            ref={searchRef}
            className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full  shadow-md' 
          />
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
            <button onClick={handleSubmitSearch} className='bg-Mybg hover:bg-MybgHover text-white py-1 px-2 rounded-md'>
              Submit
            </button>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationPerPage={limit}
          paginationTotalRows={totalRows}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={(newLimit) => setLimit(newLimit)}
          style={{ backgroundColor: '#e2e8f0' }}
          className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
          striped
          highlightOnHover
          centered  
          paginationRowsPerPageOptions={[5, 10, 15]}
        />
        </>
      )}
    </div>
    );
  };
  const DeviceDetailsCell = ({ row }) => {
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    
    
    return (
      <span>
        {row.deviceDetails.mac ? (
          <div>{row.deviceDetails.mac.macAddress}</div>
        ) : row.deviceDetails.activeCode ? (
          <div>{row.deviceDetails.activeCode.code}</div>
        ) : (
          <div className='flex flex-col'>
            <span className='mx-1'>UserName: {row.deviceDetails.m3u.userName}</span>
            <span className='mx-1 inline-flex items-center'>
              Password: {showPassword ? row.deviceDetails.m3u.password : '********'}
              <button onClick={togglePasswordVisibility} className='ml-2'>
                {showPassword ? <Hide/> : <Show/>}
              </button>
            </span>
          </div>
        )}
      </span>
    );
  };
  const Show = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6"style={{ stroke: 'black', strokeWidth: '1.5' }}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  }
  const Hide = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6" style={{ stroke: 'black', strokeWidth: '1.5' }}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
      </svg>
    );
  };
export default TableData;