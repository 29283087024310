import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: Cookies.get('token') || null,
    isAuthenticated: !!Cookies.get('token'),
    reseller: Cookies.get('Reseller') || null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      Cookies.set('token', action.payload);
    },
    clearToken: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      Cookies.remove('token');
    },
    signOut: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      const cookies = Cookies.get();
      for (const cookie in cookies) {
        Cookies.remove(cookie);
      }
      window.location.reload();
      window.location.href='/'
    },
    checkTokenValidity: (state) => {
      const token = state.token;
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          return !decodedToken.exp || decodedToken.exp > Date.now() / 1000;
        } catch (error) {
          state.token = null;
          state.isAuthenticated = false;
          Cookies.remove('token');
          window.location.reload();
          return false;
        }
      }
      return false;
    },
    setReseller: (state, action) => {
      state.reseller = action.payload;
    },
  },
});

export const { setToken, clearToken, signOut, checkTokenValidity, setReseller } = authSlice.actions;

export default authSlice.reducer;