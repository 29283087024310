import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion';
import ConfirmationModel from './ConfirmationModel/ConfirmationModel';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png'

const ServiceByName = () => {

  const {id} = useParams()

  const [packages , setPackage] = useState([])
  const [packagesName , setPackagesName] = useState([])
  const [serviceName , setServiceName] = useState([])
  const [serviceDescription , setServiceDescription] = useState([])
  const [loading , setLoading] = useState(false)

  const fetshPackages = async () =>{
    try{
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/packages/available-packages/${id}`)
      if(response.data && response.data.packages.length > 0){
        setPackage(response.data.packages)
        setServiceName(response.data.packages[0].serviceId.name)
        setServiceDescription(response.data.packages[0].serviceId.description)
      }
    }catch (error) {
      console.log('error lors du excution : ', error)
    } finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    fetshPackages();

    const intervalId = setInterval(() => {
      fetshPackages();
    }, 3 * 60 * 1000);
  
    return () => clearInterval(intervalId);
  }, []);

  const scrollFromLeftVariants = {
    hidden: { x: -3000 },
    visible: { x: 0, transition: { type: 'tween', duration: 0.8 } },
  };

  //Prix du package lors de click sur Subscribe cet dernier afficer dans model de confirmation
  const [Susbscribe_price , setSusbscribe_price] = useState()
  //Id subscribstion selected
  const [idSubSelectd , setIdSubSelected] = useState()
  //month Subscribstion selected
  const [month , setMonth] = useState()

  // Confirmation Model

  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const openConfirmation = ( packagesName , PriceSubscription , IdSelectedSubscribtion , month) => {
    setPackagesName(packagesName)
    setIdSubSelected(IdSelectedSubscribtion)
    setSusbscribe_price(PriceSubscription)
    setMonth(month)
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleConfirm = () => {
    closeConfirmation();
  };

  //Translation
  const { t } = useTranslation("global");

  //Convert duration from day to month , year ..
  const convertDuration = (duration) => {
    if (duration >= 365) {
      // Convertir en années
      const years = Math.floor(duration / 365);
      return years === 1 ? `${years} ${t("packages.year")}` : `${years} ${t("packages.years")}`;
    } else if (duration >= 30) {
      // Convertir en mois
      const months = Math.floor(duration / 30);
      return months === 1 ? `${months} ${t("packages.month")}` : `${months} ${t("packages.months")}`;
    } else {
      return duration === 1 ? `${duration} ${t("packages.day")}` : `${duration} ${t("packages.days")}`;
    }
  };

  return (
    <div className='container mx-auto flex justify-center flex-col items-center md:items-start px-5 mb-[10px] md:mt-20 lg:mt-0'>
      <div className='mt-8 md:text-start text-center'>
        <h1 className='font-medium text-xl'>{t("packages.title")} <span className=''>{serviceName}</span></h1>
        {/* <p className="text-md text-slate-500">{t("packages.description01")} <span className='font-semibold'>{serviceName}</span> {t("packages.description02")}</p> */}
        <p className="text-md text-slate-500">{serviceDescription}</p>
      </div>
      {/* Packages by service */}
      <div className='mt-2'>
            <div className='md:my-5 my-5 text-center md:text-start'>
              <span className='font-md'>{t("packages.Plans")} :</span> 
              <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("packages.All")}</button>
              <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("packages.Premium")}</button>
            </div>
            <ConfirmationModel
              isOpen={isConfirmationOpen}
              onClose={closeConfirmation}  
              onConfirm={handleConfirm}
              ServiceName={packagesName}
              PriceSubscription={Susbscribe_price}
              id={idSubSelectd}
              month={month}
            />
            <motion.div initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
              <div className='flex justify-center items-center'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                  {
                    loading 
                    ? 
                    <>
                      <Loading />
                      <div className='flex justify-center items-center'>
                        <div className='h-[300px] w-[300px]'>
                          <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                        </div>
                      </div>
                    </>
                    : 
                    <>
                      {packages.map((pac) => (
                        <div key={pac._id} className="hover:shadow-2xl transition-all duration-500 w-[400px] mx-auto">
                          <div className="cursor-pointer transition-all duration-500 border-2 hover:border-slate-400 border-slate-200 rounded-md px-2 py-2">
                            {
                              pac.serviceId.ServicePicture ? (
                                <div className="text-center text-4xl px-10 text-white md:px-28 py-10 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500">
                                  <img className='flex justify-center items-center mx-auto mt-5' src={`${process.env.REACT_APP_SERVER}/service_picture/${pac.serviceId.ServicePicture}`} alt='' />
                                </div>
                              ) : (
                                <div className="text-center text-4xl px-10 text-white md:px-28 py-10 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500">
                                  <img className='flex justify-center items-center mx-auto mt-5' src="../Pictures/default_service.png" alt='' />
                                </div>
                              )
                            }
                            <div className="my-5 flex items-center">
                              <div className='w-1/2'>
                                <span className="text-xl text-slate-500">{pac.serviceId.name}</span>&nbsp;&nbsp;
                                <span className='text-sm'>{pac.name}</span>
                              </div>
                              <div className='w-1/2 flex justify-end items-center'>
                                <span className="">{pac.etat}</span>
                              </div>
                            </div>
                            <div className="my-1 flex justify-end">
                              <span className='text-lg'>{pac.price} {pac.currency}/{convertDuration(pac.duration)}</span>
                            </div>
                            <div className="mt-5 mx-auto flex justify-center items-center">
                              <button onClick={() => { openConfirmation(pac.serviceId.name, pac.price, pac._id, pac.duration) }} className="bg-Mybg text-white hover:bg-MybgHover transition-all duration-500 rounded-md w-full py-1.5">
                                {t("packages.button")}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  }
                </div>
              </div>
            </motion.div>
      </div>
    </div>
  )
}

export default ServiceByName