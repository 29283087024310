import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axios from 'axios';

export const fetshServiceByCredit = createAsyncThunk(
  'admin/fetshServiceByCredit',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token_admin } = getState().admin;
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/service/`, {
        headers: {
          'Authorization': `Bearer ${token_admin}`,
          'Accept-Language': localStorage.getItem('language'),
        },
      });
      
      return response.data.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    token_admin: Cookies.get('token_admin') || null,
    isAuthenticated: !!Cookies.get('token_admin'),
    serviceByCredit: [],
    loading: false,
    error: null,
  },
  reducers: {
    setTokenAdmin: (state, action) => {
      state.token_admin = action.payload;
      state.isAuthenticated = true;
      Cookies.set('token_admin', action.payload);
    },
    cleartoken_admin: (state) => {
      state.token_admin = null;
      state.isAuthenticated = false;
      Cookies.remove('token_admin');
    },
    signOut: (state) => {
      state.token_admin = null;
      state.isAuthenticated = false;
      Cookies.remove('token_admin');
      window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetshServiceByCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetshServiceByCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceByCredit = action.payload;
      })
      .addCase(fetshServiceByCredit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setTokenAdmin, cleartoken_admin, signOut } = adminSlice.actions;
export const { actions, reducer } = adminSlice;
export default adminSlice;