import React, { useState } from 'react';
import Source from '../AssetsAdmin/Source';
import Commit from '../AssetsAdmin/Commit';
import Refresh from '../../Assets/Icons/Refresh';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Success from '../../Assets/Alert/Success';
import Error from '../../Assets/Alert/Error';
import { useDispatch } from 'react-redux';
import { fetshServiceByCredit } from '../../Redux/Slices/AdminSlice'

const ApiPage = () => {
    const data = [
        {
            id: 2,
            libelle: "Services",
            etat: "Building",
            status: "300",
            source: "Mega",
            description: "Reload Data",
            path: '/mega/service'
        },
        {
            id: 1,
            libelle: "Packages",
            etat: "Ready",
            status: "200",
            source: "Mega",
            description: "Reload Data",
            path: '/mega/packages'
        },
        {
            id: 3,
            libelle: "Bouquets",
            etat: "Ready",
            status: "201",
            source: "Mega",
            description: "Reload Data",
            path: '/mega/bouquets'
        },
    ];
    const [loadingRow, setLoadingRow] = useState(null);
    const token_admin = useSelector((state) => state.admin.token_admin);
    const [msgSuccess , setMsgSuccess] = useState('')
    const [msgError , setMsgError] = useState('')
    const dispatch = useDispatch()

    const columns = [
        {
            name: 'API SERVICE',
            selector: (row) => row.libelle,
            sortable: true,
            center: true,
        },
        {
            name: 'ETAT',
            selector: (row) => row.etat,
            cell: (row) => (
                <div className="inline-flex justify-center items-center space-x-1">
                    <span className={loadingRow === row.id ? 'text-yellow-500' : 'text-green-500'}><Commit /></span>
                    <p className={loadingRow === row.id ? 'text-yellow-500' : 'text-green-500'}>{loadingRow === row.id ? 'Building' : 'Ready'}</p>
                </div>
            ),
            sortable: true,
            center: true,
        },
        {
            name: 'STATUS',
            selector: (row) => row.status,
            cell: (row) => (
                <span className={loadingRow === row.id ? 'bg-yellow-500 text-white py-1 px-2 rounded-md' : 'bg-green-500 text-white py-1 px-2 rounded-md'}>{loadingRow === row.id ? '102' : '200'}</span>
            ),
            sortable: true,
            center: true,
        },
        {
            name: 'SERVICE',
            selector: (row) => row.source,
            cell: (row) => (
                <div className='inline-flex justify-center items-center space-x-2'>
                    <span className='text-orange-500'><Source /></span>
                    <p>{row.source}</p>
                </div>
            ),
            sortable: true,
            center: true
        },
        {
            name: 'DESCRIPTION',
            selector: (row) => row.description,
            sortable: true,
            center: true
        },
        {
            name: 'ACTIONS',
            cell: (row) => (
                <div className="flex justify-center items-center">
                    <button disabled={loadingRow === row.id} onClick={() => { executeMegaApi(row.path, row.id) }} className='text-green-500'>
                        {loadingRow === row.id ?
                            <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black"></l-squircle>
                            :
                            <Refresh />
                        }
                    </button>
                </div>
            ),
            center: true
        },
    ];

    const executeMegaApi = async (path, rowId) => {
        try {
            setLoadingRow(rowId);
            const response = await axios.get(`${process.env.REACT_APP_SERVER}${path}`, {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language': localStorage.getItem('language')
                }
            });
            if (response.data) {
                dispatch(fetshServiceByCredit());
                setMsgSuccess(response.data.message)
            }
        } catch (error) {
            console.error(error);

            if ( error.response.data.error.error_description ) setMsgError(error.response.data.error.error_description);
            else setMsgError(error.response.data.details);
        } finally {
            setLoadingRow(null);
        }
    };

    return (
        <div className='flex flex-col mx-2 lg:mx-20'>
            <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
                <h1 className='text-2xl text-start'>API's</h1>
                <p className='text-slate-500'>API Management Interface that allows reloading data from APIs.</p>
            </div>

            {msgSuccess && (
                <Success message={msgSuccess} />
            )}

            {msgError && (
                <Error message={msgError} />
            )}
            <div className='flex mt-1 flex-wrap flex-col'>
                {data ? (
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        paginationTotalRows={data.length}
                        className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
                        striped
                        highlightOnHover
                        paginationPerPage={data.length}
                        paginationRowsPerPageOptions={[5, 10, 15]}
                    />
                ) : (
                    <div className='border-2 border-slate-200 bg-slate-50 hover:shadow-lg rounded-md py-4 px-2 transition-all duration-500 flex items-center justify-center'>
                        <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black"></l-squircle>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApiPage;