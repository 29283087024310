import React, { useCallback, useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import PaypalIcon from '../../../../Assets/Pics/PaypalIcon.png';
import CryptoIcon from '../../../../Assets/Pics/CryptoIcon.png';
import StripeIcon from '../../../../Assets/Pics/StripeIcon.png';
import GooglePayIcon from '../../../../Assets/Pics/GooglePayIcon.png';
import axios from 'axios';
import LoadingTheme from '../../../../Assets/Loading/LoadingTheme';
import Error from '../../../../Assets/Alert/Error';
import { squircle } from 'ldrs';

const ModalExtendSubscription = ({isOpen, onClose , id }) => {
    squircle.register();
    const handleCancel = () => {
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const [service, setService] = useState([]);
    const [packages, setPackage] = useState([]);
    const [error, setError] = useState('');
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState();
    const [selectedPackageId, setSelectedPackageId] = useState();

    const handlePaymentMethodSelect = (method) => {
        setSelectedMethod(method);
    };

    const fetshService = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/getEnableSerive');
            if (response.data) {
                setService(response.data);
            }
        } catch (error) {
            console.error('Error fetching services:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetshPackages = useCallback(async () => {
        if (!selectedServiceId) return;

        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/packages/available-packages/${selectedServiceId}`);
            if (response.data && response.data.packages.length > 0) {
                setPackage(response.data.packages);
            }
        } catch (error) {
            console.log('Error during execution:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedServiceId]);

    useEffect(() => {
        fetshService();
    }, []);

    useEffect(() => {
        fetshPackages();
    }, [fetshPackages]);

    const SubmitExtend = async () => {
        try{
            const dataToSend = {
                newPackageId: selectedPackageId,
                subscriptionId: id,
                paymentMethod: selectedMethod,
              };
            setLoadingSubmit(true);
            const response = await axios.put(process.env.REACT_APP_SERVER + `/subscription/extend-subscription` , dataToSend , {
                headers: {
                    'secret_key': process.env.REACT_APP_KEY_BACKEND,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept-Language': localStorage.getItem('language')
                }
            })
            if(response.data) {
                await window.open(response.data.link, '_blank');
            }else {
                setError(response.data.message);
            }
        } catch (error) {
            setError(error.response.data.error || 'Une erreur est survenue lors du paiement.');
            console.error(error);
        } finally {
            setLoadingSubmit(false)
        }
    }

  return (
    <motion.div
        className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
    >
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
                <p className="text-lg font-medium mb-4">Extend Subscription</p>
                <p className='text-md text-slate-500'>
                    This action allows you to pay or update a subscription payment.
                </p>
                {loading && <LoadingTheme/>}
                {error && <Error message={error}/>}
                <div className='flex justify-center items-center md:flex-row flex-col space-x-0 md:space-x-2 mt-2'>
                    <div className='flex flex-col md:w-1/2 w-full'>
                        <label>Services :</label>
                        <select className='py-2.5 w-full px-2 rounded-md border-2 border-Mybg bg-white' value={selectedServiceId} onChange={(e)=>{setSelectedServiceId(e.target.value)}}>
                            <option>Select service</option>
                            {service && service.map(item => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}

                        </select>
                    </div>
                    <div className='flex flex-col md:w-1/2 w-full'>
                        <label>Packages :</label>
                        <select className='py-2.5 w-full px-2 rounded-md border-2 border-Mybg bg-white' value={selectedPackageId} onChange={(e)=>{setSelectedPackageId(e.target.value)}}>
                        <option>Select Packages</option>
                            {packages && packages.map(item => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}

                        </select>
                    </div>
                </div>
                <div className='flex justify-center items-center mt-1 space-x-1'>
                    <button
                        className={`hover:scale-105 w-full my-1 transition-all inline-flex items-center justify-center border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'PayPal' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('paypal')}
                    >
                        <img src={PaypalIcon} alt="Paypal" className='w-[60px] p-2' />
                    </button>
                    <button
                        disabled
                        className={`bg-slate-300 w-full my-1 transition-all inline-flex items-center justify-center border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'Crypto' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('crypto')}
                    >
                        <img src={CryptoIcon} alt="Cryptocurrency" className='w-[60px] p-2'/>
                    </button>
                    <button
                        className={`hover:scale-105 w-full my-1 transition-all inline-flex items-center justify-center border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'Stripesa' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('stripe')}
                    >
                        <img src={StripeIcon} alt="Stripe" className='w-[60px] p-2'/>
                    </button>
                    <button
                        disabled
                        className={`bg-slate-300 w-full my-1 transition-all inline-flex items-center justify-center py-2 md:py-[13px] border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'GooglePay' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('googlePay')}
                    >
                        <img src={GooglePayIcon} alt="Google Pay" className='w-[60px] p-2'/>
                    </button>
                </div>
                <div className="flex justify-end mt-2 space-y-1 flex-col">
                    <button className="inline-flex items-center justify-center bg-Mybg hover:bg-MybgHover space-x-2 text-white px-4 py-2 rounded" onClick={SubmitExtend}>
                        <p>Extend</p>
                        {loadingSubmit && (<span>
                            <l-squircle size="15" stroke="2" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="white"></l-squircle>
                        </span>)}
                    </button>
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 rounded" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
    </motion.div>
  )
}

export default ModalExtendSubscription
